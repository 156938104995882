import React from "react";
import vegeelLogo from "assets/images/vegeel-logo.svg";
import { sidebarTop, sidebarMiddle, sidebarBottom } from "constants/home";
import Navlink from "components/navlink";
import { useAppContext } from "context/home/app";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const { pathname } = useLocation();
  const { getUserInfo } = useAppContext();
  const userInfo = getUserInfo();

  const userRole = userInfo.roleName;

  const filteredSidebarMiddle =
    userRole === "ADMIN" || userRole === "SUPER_ADMIN"
      ? sidebarMiddle
      : sidebarMiddle.filter((item) => item.title !== "Help Requests");

  const showSidebarBottom = userRole === "ADMIN" || userRole === "SUPER_ADMIN";

  return (
    <>
      {!pathname.includes("preview-blog") && (
        <div className="h-full w-[17%] min-w-[288px] py-[12px] bg-[#060B59] overflow-y-hidden">
          <div className="px-[22px] py-[16px] flex items-center">
            <img src={vegeelLogo} alt="vegeel logo" />
          </div>
          <div className="bg-[#041D73] py-[12px] px-[20px] flex gap-[10px]">
            <img
              src={
                userInfo.profilePicture ||
                "https://i.pinimg.com/474x/16/3e/39/163e39beaa36d1f9a061b0f0c5669750.jpg"
              }
              alt=""
              className="w-[32px] h-[32px] rounded-full"
            />
            <h1 className="text-[14px] font-medium leading-[24px]  text-white">
              {userInfo.firstName} {userInfo.lastName}
            </h1>
          </div>
          <div className="px-[22px] h-fit mx-auto flex flex-col gap-2 my-2">
            {sidebarTop.map((link, i) => {
              return (
                <Navlink
                  icon={link.icon}
                  link={link.link}
                  title={link.title}
                  key={i}
                />
              );
            })}
          </div>

          <div className="px-[22px] h-fit mx-auto flex flex-col gap-2 my-2 border-y-[0.5px] border-[#D7DFE9] py-3">
            {filteredSidebarMiddle.map((link, i) => {
              return (
                <Navlink
                  icon={link.icon}
                  link={link.link}
                  title={link.title}
                  key={i}
                />
              );
            })}
          </div>

          {showSidebarBottom && (
            <div className="px-[22px] h-fit mx-auto flex flex-col gap-2 my-2 py-3">
              {sidebarBottom.map((link, i) => {
                return (
                  <Navlink
                    icon={link.icon}
                    link={link.link}
                    title={link.title}
                    key={i}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}
