import React, { useEffect, useState } from "react";
import Sidebar from "components/sidebar";
import Header from "components/header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getTokenFromLocalStore } from "utils";
import Loader from "components/loader";

const Authenticated: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getTokenFromLocalStore("access_token") || null;
    if (!token) {
      navigate("/auth");
    } else {
      setIsLoading(false);
      return;
    }
  }, [navigate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="h-full w-full flex">
      <Sidebar />
      <div
        className={`w-[83%] !bg-[#FAFBFC] ${
          pathname.includes("preview-blog") ? "w-full" : ""
        }`}
      >
        <Header />
        <div className="w-full px-10 h-[calc(100%-70px)] overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Authenticated;
