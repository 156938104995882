import { Modal } from "antd";
import { ReactComponent as img1 } from "assets/icons/logout-04.svg";
import CustomIcon from "components/custom-icon";
import { clearAll } from "utils";

interface Props {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  loading: boolean;
}

const LogoutModal: React.FC<Props> = ({
  open,
  handleOk,
  handleCancel,
  loading,
}) => {
  const handleLogout = async () => {
    clearAll(true);
  };
  return (
    <Modal
      open={open}
      onOk={handleOk}
      centered
      width={400}
      onCancel={handleCancel}
      footer={
        <div className=" w-full flex items-center gap-6 mt-[2rem] mb-4">
          <button
            className="w-full py-2 rounded-md bg-white border font-medium text-sm border-[#D7DFE9]"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="w-full py-2 rounded-md text-white font-medium text-sm bg-[#E92215] border border-[#E92215]"
            key="submit"
            onClick={handleLogout}
          >
            {loading ? "Logging out" : "Log out"}
          </button>
        </div>
      }
    >
      <div className="w-14 bg-[#FFF5F4] aspect-square rounded-full flex items-center justify-center ">
        <CustomIcon SvgIcon={img1} size={24} />
      </div>
      <p className=" my-4 text-lg font-bold">Log out?</p>
      <p className="text-[#455468] text-sm">
        Are you sure you want to log out? All your unsaved progress will be
        lost.
      </p>
    </Modal>
  );
};
export default LogoutModal;
